import { IPlayerRootReducerShape } from '../rootPlayerStore';

export const getFunnelState = (state: IPlayerRootReducerShape) => {
  return state.funnelState;
};

export const getWasChaptersToggled = (state: IPlayerRootReducerShape) => {
  return state.funnelState.wasChaptersToggled;
};

export const getStopAnalyticsNodeId = (state: IPlayerRootReducerShape) => {
  return state.funnelState.stopAnalyticsNodeId;
};
