import * as React from 'react';
import Button from '../Button/Button';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { updateChaptersVisibility } from '../../../store/chapters/actions';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import { getIsChaptersVisible } from '../../../store/chapters/selectors';

export const ToggleChapters = ({ player }: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const isVisible = useSelector(getIsChaptersVisible);

  const handleClick = useCallback(() => {
    dispatch(
      updateChaptersVisibility({ isVisible: !isVisible, initiatedBy: 'click' })
    );
  }, [dispatch, isVisible]);

  const skin = usePlayerSkin();
  const ChaptersIcon = skin.icons.chapters;

  if (!player.chapters || !player.chapters.enabled) return null;

  return (
    <Button
      className={skin.classes.toggleChapters__root}
      config={player}
      onClick={handleClick}
      icon={<ChaptersIcon />}
      title={player.chapters.label}
    />
  );
};
