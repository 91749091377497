import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export interface IChaptersShape {
  isVisible: boolean;
}

// Normally we'd want to have all data required for render here,
// but since data there is static we can cheat and grab the data from Player view
const initialState: IChaptersShape = {
  isVisible: false,
};

type IAction = ActionType<typeof actions>;
const reducer = (
  state: IChaptersShape = initialState,
  action: IAction
): IChaptersShape => {
  switch (action.type) {
    case getType(actions.updateChaptersVisibility):
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };
  }

  return state;
};

export default reducer;
