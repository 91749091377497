import { isEqual } from 'lodash-es';
import { put, race, select, take, takeLatest } from 'redux-saga/effects';
import { PlayerMode } from '../../components/types/defaultPropTypes';
import { updateChaptersVisibility } from '../chapters/actions';
import { setForceAspectRatio } from '../containerDimensions/actions';
import { getIsForceAspectRatio } from '../containerDimensions/selectors';
import {
  setThumbnailCustomVideo,
  updateThumbnail,
  updateVisibility,
} from '../thumbnail/actions';
import { getThumbnail } from '../thumbnail/selectors';
import { waitForChange } from '../utils';
import {
  changeLogoVisibility,
  hideControls,
  showControls,
} from '../videoControls/actions';
import {
  changeControls,
  playEnded,
  requestPlay,
  requestSeek,
} from '../videoState/actions';
import {
  getCurrentTime,
  getIsVideoReady,
  getVideoState,
} from '../videoState/selectors';
import {
  newSourceConfigurationEmitted,
  preloadNextVideo,
  registerCallbacks,
  requestNavigateToOtherNode,
  setSourceConfiguration,
} from './actions';
import { getCurrentSourceConfiguration, getIsVideoChanging } from './selectors';
import { AxiosError, AxiosResponse } from 'axios';
import { getRequest, makeUrl } from '@voomly/utils';
import { IPlayerVideo } from '../../types/video';

function* handleNewSourceConfiguration(
  action: ReturnType<typeof newSourceConfigurationEmitted>
) {
  const currentSourceConfig = getCurrentSourceConfiguration(yield select());
  const sourceConfig = action.payload;

  const fileChanged = currentSourceConfig
    ? Boolean(currentSourceConfig?.file?.id !== sourceConfig.file.id)
    : false;

  if (
    !fileChanged &&
    sourceConfig.playerConfig &&
    [PlayerMode.TIMELINE_EDITOR, PlayerMode.THUMBNAIL_EDITOR].includes(
      sourceConfig.playerMode
    )
  ) {
    const prevThumbnail = currentSourceConfig?.playerConfig.thumbnail;
    const currentThumbnail = sourceConfig.playerConfig.thumbnail;

    // If a video was played a thumbnail update will not be visible
    if (!isEqual(currentThumbnail, prevThumbnail)) {
      yield put(updateVisibility(true));
      yield put(updateThumbnail(currentThumbnail));
      yield put(changeControls({ hasStarted: true }));
    }
  }

  // Get video for thumbnail if needed
  if (
    sourceConfig.playerConfig.thumbnail?.type === 'videoLoop' &&
    sourceConfig.playerConfig.thumbnail?.customVideoId
  ) {
    const [err, response]: [AxiosError, AxiosResponse] = yield getRequest(
      makeUrl(
        `/videos/${sourceConfig.playerConfig.thumbnail?.customVideoId}/voomly`
      )
    );

    const thumbnailFile: IPlayerVideo | undefined = response?.data;
    if (!err && thumbnailFile) {
      yield put(setThumbnailCustomVideo(thumbnailFile));
    } else {
      console.error(
        `Cannot load thumbnail video with id ${sourceConfig.playerConfig.thumbnail?.customVideoId}`
      );
    }
  }

  // when config is updated, we need to find the time when we can update
  // if file changed -> wait until it preloads, then starts playing, then apply everything
  // if file is the same, then apply instantaneously

  // Already playing a video, but requested playing another video
  if (fileChanged) {
    yield put(preloadNextVideo(sourceConfig));
    return;
  }

  // preloading next video, do nothing
  if (getIsVideoChanging(yield select())) {
    return;
  }

  if (
    sourceConfig.playbackSettings.currentTime !==
      currentSourceConfig?.playbackSettings.currentTime &&
    sourceConfig.playbackSettings.currentTime > 0 &&
    getIsVideoReady(yield select())
  ) {
    yield put(
      requestSeek({
        time: sourceConfig.playbackSettings.currentTime,
        type: 'toTime',
      })
    );
  }

  if (
    sourceConfig.playbackSettings.forceAspectRatio &&
    !getIsForceAspectRatio(yield select())
  ) {
    yield put(
      setForceAspectRatio(sourceConfig.playbackSettings.forceAspectRatio)
    );
  }

  const oldPlayer = currentSourceConfig?.playerConfig;
  const hadPlayerBefore = Boolean(oldPlayer);
  // need to set player since we use it in functions called in blocks

  // initial player assignment
  if (!hadPlayerBefore) {
    yield put(updateVisibility(true));
    yield put(updateThumbnail(sourceConfig.playerConfig.thumbnail));

    yield put(changeLogoVisibility({ isVisible: true }));

    if (sourceConfig.playerConfig.controls.visibleControlsOnLoad) {
      yield put(showControls());
    }

    if (
      sourceConfig.playerConfig.chapters &&
      sourceConfig.playerConfig.chapters.enabled &&
      sourceConfig.playerConfig.chapters.isVisibleOnLoad
    ) {
      yield put(updateChaptersVisibility({ isVisible: true }));
    }
  } else {
    const thumbnail = getThumbnail(yield select());

    if (!thumbnail) {
      console.error('No thumbnail!');
      return;
    }

    // If thumbnail is visible we'd better update it in case user
    // changed the thumbnail image
    if (thumbnail.isVisible) {
      yield put(updateVisibility(thumbnail.isVisible));
      yield put(updateThumbnail(sourceConfig.playerConfig.thumbnail));
    }

    const videoControls = getVideoState(yield select());

    if (
      !videoControls.hasStarted &&
      sourceConfig.playerConfig.general.autoplay &&
      [PlayerMode.TIMELINE_EDITOR, PlayerMode.THUMBNAIL_EDITOR].includes(
        sourceConfig.playerMode
      )
    ) {
      yield put(requestPlay({}));
    }

    if (!videoControls.hasStarted) {
      if (sourceConfig.playerConfig.controls.visibleControlsOnLoad) {
        yield put(showControls());
      } else {
        yield put(hideControls());
      }
    }
  }

  yield put(setSourceConfiguration(sourceConfig));
}

function* handleCallbackRegistration(
  action: ReturnType<typeof registerCallbacks>
) {
  while (true) {
    const {
      onTimeChange,
      onPlayEnd,
      onNodeRequest,
    }: {
      onTimeChange: number | undefined;
      onPlayEnd: ReturnType<typeof playEnded> | undefined;
      onNodeRequest: ReturnType<typeof requestNavigateToOtherNode> | undefined;
    } = yield race({
      onTimeChange: waitForChange(getCurrentTime),
      onPlayEnd: take(playEnded),
      onNodeRequest: take(requestNavigateToOtherNode),
    });

    if (onTimeChange) {
      action.payload.onTimeChange?.(onTimeChange);
    }

    if (onPlayEnd) {
      action.payload.onVideoFinished?.();
    }

    if (onNodeRequest) {
      action.payload.onNavigateToOtherNodeRequest?.(
        onNodeRequest.payload.nodeId,
        onNodeRequest.payload.timestamp
      );
    }
  }
}

export function* sourceConfigurationSaga() {
  yield takeLatest(newSourceConfigurationEmitted, handleNewSourceConfiguration);
  yield takeLatest(registerCallbacks, handleCallbackRegistration);
}
